<template>
  <ion-list v-if="resources != null">
    <ion-list-header class="ion-margin-bottom">
      <h3>Exam Students</h3>
    </ion-list-header>
    <ion-list>
      <ion-item-sliding
        lines="full"
        v-for="item in resources.data"
        :key="item.id"
        :ref="`items-${item.id}`"
      >
        <ion-item-options side="start" v-if="examData.exam_type.name == 'Offline'">
          <ion-item-option color="light" @click="getAdmitCard(item)">
            <span>Download Admit Card</span>
          </ion-item-option>
        </ion-item-options>
        <ion-item>
          <ion-label>
            {{ item.user.user_detail.name }}
            <br />
            <p>
              Class: {{ item.user.student.section_standard.standard.name }} -
              {{ item.user.student.section_standard.section.name }} Roll:
              {{ item.user.student.roll_no }}
            </p>
          </ion-label>
          <ion-select
            placeholder="Status"
            interface="popover"
            :value="item.exam_user_state_id"
            @IonChange="updateExamUserState(item, $event)"
          >
            <ion-select-option
              :value="4"
              v-if="
                item.exam_user_state_id == 2 || item.exam_user_state_id == 4
              "
              >Provisional</ion-select-option
            >
            <ion-select-option
              :value="2"
              v-if="
                item.exam_user_state_id == 2 || item.exam_user_state_id == 4
              "
              >Inactive</ion-select-option
            >
            <ion-select-option v-if="item.exam_user_state_id == 1" :value="1"
              >Active</ion-select-option
            >
            <ion-select-option v-if="item.exam_user_state_id == 3" :value="3"
              >Completed</ion-select-option
            >
          </ion-select>
        </ion-item>
      </ion-item-sliding>
    </ion-list>
  </ion-list>
  <ion-list v-else>
    <ion-list-header>
      <p>No students found</p>
    </ion-list-header>
  </ion-list>
</template>
<script>
import {
  IonList,
  IonListHeader,
  alertController,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonItemSliding,
  IonItemOptions,
  IonItemOption,
  loadingController,
} from "@ionic/vue";

import {} from "ionicons/icons";

import moment from "moment";

import base_url from "../../../../apis/base_url";

export default {
  props: {
    resources: {
      type: Object,
      required: true,
    },
    exam: {
      type: String,
      required: true,
    },
  },

  components: {
    IonList,
    IonListHeader,
    IonItem,
    IonLabel,
    IonSelect,
    IonSelectOption,
    IonItemSliding,
    IonItemOptions,
    IonItemOption,
  },
  data() {
    return {
      moment,

      base_url,

      xhrError: {
        header: null,
        description: null,
      },
    };
  },

  computed: {
    token() {
      return this.$store.getters["auth/token"];
    },
    examData() {
      return this.$store.getters["exam/specificResource"];
    },
  },

  methods: {
    slide(id) {
      this.$refs[`items-${id}`].$el.open();
    },
    alerter(header, message, buttons = ["close"]) {
      const alert = alertController.create({
        header: header,
        message: message,
        buttons: buttons,
      });

      return alert;
    },

    loader(message) {
      const loading = loadingController.create({
        message: message,
        backdropDismiss: false,
      });

      return loading;
    },

    setErrorResponse(error) {
      if (error.response) {
        this.xhrError.header = error.response.data.header;
        this.xhrError.message = error.response.data.message;
      } else if (error.request) {
        this.xhrError.header = error.message || error.request;
        this.xhrError.message = "Please check your connection and try again";
      } else {
        this.xhrError.header = error.message;
        this.xhrError.message = "Something went wrong. Try again later";
      }
    },

    async updateExamUserState(item, event) {
      let data = {
        id: item.id,
        exam_user_state_id: event.target.value,
      };

      try {
        await this.$store.dispatch("examUser/updateResource", data);
      } catch (error) {
        event.target.value = item.exam_user_state_id;
        this.setErrorResponse(error);
        const alertDialog = await this.alerter(
          this.xhrError.header,
          this.xhrError.message
        );
        alertDialog.present();
      }
    },

    async checkAdmitCard(item) {
      const spinner = await this.loader("Checking for admit card...");
      spinner.present();
      try {
        await this.$store.dispatch("examUser/specificResource", item.id);
        const exam_user = this.$store.getters["examUser/specificResource"];
        return exam_user.exam_user_state.name != "Inactive";
      } catch (error) {
        this.setErrorResponse(error);
        const alertDialog = await this.alerter(
          this.xhrError.header,
          this.xhrError.message
        );
        alertDialog.present();
      } finally {
        spinner.dismiss();
      }
    },

    downloadAdmitCard(item) {
      const user_id = item.user_id;
      const exam_id = item.exam_id;
      const url = `${this.base_url}api/v1/admit-card-print/${exam_id}?pat=${this.token}|${user_id}`;
      const new_download_button = document.createElement("a");
      new_download_button.href = url;
      new_download_button.download = "admit_card.pdf";
      new_download_button.click();
    },

    async getAdmitCard(item) {
      const admitCardCheck = await this.checkAdmitCard(item);
      if (admitCardCheck === true) {
        this.downloadAdmitCard(item);
      } else {
        const alertDialog = await this.alerter(
          "Not Available",
          "Admit card not available, please change status to provisional"
        );
        alertDialog.present();
      }
    },

    async fetchExam() {
      await this.$store.dispatch("exam/specificResource", this.exam);
    },
  },

  created() {
    this.fetchExam();
  },
};
</script>