<template>
  <base-layout :title="title" :backLink="backLink" :reloadable="reloadable">
    <ion-refresher slot="fixed" @ionRefresh="refresh($event)">
      <ion-refresher-content></ion-refresher-content>
    </ion-refresher>
    <ion-grid fixed>
      <ion-row>
        <ion-col size="12">
          <list-exam-user :resources="resources" :exam="exam"></list-exam-user>
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-infinite-scroll threshold="100px" @ionInfinite="loadData($event)">
      <ion-infinite-scroll-content
        loading-spinner="bubbles"
        loading-text="Loading more data..."
      >
      </ion-infinite-scroll-content>
    </ion-infinite-scroll>

    <ion-fab vertical="bottom" horizontal="end" slot="fixed">
      <ion-fab-button @click="setFilterModalState(true)">
        <ion-icon :ios="funnelOutline" :md="funnelOutline"></ion-icon>
      </ion-fab-button>
    </ion-fab>
    <ion-modal
      :is-open="filterModalState"
      @didDismiss="setFilterModalState(false)"
    >
      <base-modal
        title="Create Exam Schedule"
        @closeModal="setFilterModalState(false)"
      >
        <filter-exam-user
          @closeModal="setFilterModalState(false)"
          :exam_id="exam"
        ></filter-exam-user>
      </base-modal>
    </ion-modal>

  </base-layout>
</template>

<script>
import BaseLayout from "../../Base/BaseLayout";
import BaseModal from "../../Base/BaseModal";
import ListExamUser from "../../Components/Exam/ExamUser/ListExamUser";
import FilterExamUser from "../../Components/Exam/ExamUser/FilterExamUser.vue"

import {
  IonGrid,
  IonRow,
  IonCol,
  IonFab,
  IonIcon,
  IonFabButton,
  IonModal,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonRefresher,
  IonRefresherContent,
} from "@ionic/vue";

import {
  funnelOutline,
} from "ionicons/icons";

export default {
  props: ["exam", "title"],

  components: {
    BaseLayout,
    BaseModal,

    ListExamUser,
    FilterExamUser,
    IonGrid,
    IonRow,
    IonCol,
    IonFab,
    IonIcon,
    IonFabButton,
    IonModal,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonRefresher,
    IonRefresherContent,
  },

  data() {
    return {
      backLink: "exam.list",
      reloadable: true,

      filterModalState: false,
      funnelOutline,
    };
  },

  computed: {
    resources() {
      return this.$store.getters["examUser/resources"];
    },
    shouldReload() {
      return this.$store.getters["reload"] === this.$route.name;
    },
  },

  watch: {
    shouldReload(current) {
      if (current) {
        this.refresh();
        this.$store.dispatch("clearReload");
      }
    },
  },

  methods: {
    async fetch(refresh = true) {
      await this.$store.dispatch("examUser/resources", {
        refresh: refresh,
        resourceId: this.exam,
      });
    },
    loadData(event) {
      this.fetch(false);
      event.target.complete();
    },
    async refresh(event) {
      await this.$store.dispatch("examUser/resources", {
        refresh: true,
        resourceId: this.exam,
      });
      if (event) {
        event.target.complete();
      }
    },
    setCreateModalState(state) {
      this.createModalState = state;
    },

    setFilterModalState(state) {
      this.filterModalState = state;
    },
  },

  created() {
    this.fetch();
  },
};
</script>

<style scoped>
</style>
